import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { LoginLink } from "../features/account/LoginLink";
import { RegisterLink } from "../features/account/RegisterLink";
import { useAuth } from "../context/authContext";

function IndexPage() {
    const { isLoggedIn } = useAuth();

    return (
        <Layout variant={`home`}>
            <SEO
                description="Huge CCG & TCG database and Free International card trading tool"
                keywords={[`ccg`, `collectible`, `card`, `game`, `trade`]}
                title="Home"
            />
            <Container disableGutters maxWidth="lg">
                <Paper>
                    <Grid className="mb-8" container spacing={0}>
                        <Grid item sm={4} xs={12}>
                            <StaticImage
                                alt="Players: Complete your decks"
                                layout="fullWidth"
                                placeholder="blurred"
                                src="../images/ui/banners/home-1.jpg"
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <StaticImage
                                alt="Collectors: Complete your sets"
                                layout="fullWidth"
                                placeholder="blurred"
                                src="../images/ui/banners/home-2.jpg"
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <RegisterLink>
                                <StaticImage
                                    alt="Free CCG Trader account"
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    src="../images/ui/banners/home-3.jpg"
                                />
                            </RegisterLink>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid
                            className="pb-8 px-8 sm:pb-12"
                            item
                            sm={4}
                            xs={12}
                        >
                            <Typography
                                className=""
                                component="h2"
                                gutterBottom
                                variant="h3"
                            >
                                Step 1.
                            </Typography>
                            Sign up for your free account
                        </Grid>
                        <Grid
                            className="pb-8 px-8 sm:pb-12"
                            item
                            sm={4}
                            xs={12}
                        >
                            <Typography
                                className=""
                                component="h2"
                                gutterBottom
                                variant="h3"
                            >
                                Step 2.
                            </Typography>
                            Select the cards you would like to trade
                        </Grid>
                        <Grid
                            className="pb-8 px-8 sm:pb-12"
                            item
                            sm={4}
                            xs={12}
                        >
                            <Typography
                                className=""
                                component="h2"
                                gutterBottom
                                variant="h3"
                            >
                                Step 3.
                            </Typography>
                            Respond to offers and start trading for free
                        </Grid>
                    </Grid>
                    <section className="pb-8 text-center">
                        {!isLoggedIn() ? (
                            <>
                                <RegisterLink className="bg-primary hover:bg-primary-dark my-4 p-4 rounded text-3xl">
                                    Sign up now
                                </RegisterLink>
                                <Typography>
                                    or{` `}
                                    <LoginLink
                                        className="font-bold"
                                        redirect="/dashboard"
                                    >
                                        Login
                                    </LoginLink>{` `}
                                    &raquo;
                                </Typography>
                            </>
                        ) : (
                            <Link to="/dashboard">
                                <button className="bg-primary hover:bg-primary-dark my-4 p-4 rounded text-3xl">
                                    Go to Dashboard
                                </button>
                            </Link>
                        )}
                        <h1 className="text-4xl my-8 p-3">
                            Huge CCG & TCG database and Free International card
                            Trading Tool
                        </h1>
                    </section>
                </Paper>
            </Container>
        </Layout>
    );
}

export default IndexPage;
